import internet from "../../../../content/blog/catching-up/internet.mp4";
import worthy from "../../../../content/blog/catching-up/worthy.mp4";
import allday from "../../../../content/blog/catching-up/allday.mp4";
import Video from 'components/Video';
import * as React from 'react';
export default {
  internet,
  worthy,
  allday,
  Video,
  React
};